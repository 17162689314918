<template>
    <div class="main-box">
        <div class="pd-box">
<!--            <a-form :label-col="labelCol" :wrapper-col="wrapperCol">-->
                <div v-for="(formItem, formi) in formLists" :key="formi" >
                    <div class="content" >

                        <div style="font-weight: bold; display: flex;width: 60%;justify-content: space-around;"  :style="{fontWeight : (formItem.PROJECT_NAME==='现金流入小计') ? '' : 'bold'}">
                            <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" :label="formItem.PROJECT_NAME"  :style="{fontSize:(formItem.PROJECT_NAME==='现金流入小计'?'12px':'15px')}" style="width: 50%;">
<!--                                <a-input-number :disabled="(!isEdit || formItem.PROJECT_NAME === '一、经营活动产生的现金流量' || formItem.PROJECT_NAME === '二、投资活动产生的现金流量' || formItem.PROJECT_NAME === '三、筹资活动产生的现金流量') ? true : false"-->
<!--                                                v-if="formItem.PROJECT_NAME==='一、经营活动产生的现金流量'?false:true" :placeholder="(formItem.PROJECT_NAME === '一、经营活动产生的现金流量' || formItem.PROJECT_NAME === '二、投资活动产生的现金流量' || formItem.PROJECT_NAME === '三、筹资活动产生的现金流量') ? '' : ''" style="width: 200px;margin-left: 50px;"></a-input-number>-->
                            </a-form-item>
                            <a-form-item style="width: 40%;" :label-col="labelCol" :wrapper-col="wrapperCol">
                                <a-input-number v-model:value="formItem.AMOUNT"
                                                v-if="(formItem.PROJECT_NAME==='一、经营活动产生的现金流量'|| formItem.PROJECT_NAME === '二、投资活动产生的现金流量' || formItem.PROJECT_NAME === '三、筹资活动产生的现金流量')?false:true" :disabled="(!isEdit || formItem.PROJECT_NAME === '一、经营活动产生的现金流量' || formItem.PROJECT_NAME === '二、投资活动产生的现金流量' || formItem.PROJECT_NAME === '三、筹资活动产生的现金流量') ? true : false" :placeholder="(formItem.PROJECT_NAME === '一、经营活动产生的现金流量' || formItem.PROJECT_NAME === '二、投资活动产生的现金流量' || formItem.PROJECT_NAME === '三、筹资活动产生的现金流量') ? '' : '请输入金额'" style="width: 400px;"></a-input-number>
                            </a-form-item>
                        </div>
                        <div style="display: flex;width: 60%;justify-content: space-around;" v-for="(cItem, ci) in formItem.childrenList" :key="ci" :style="{fontWeight : (cItem.PROJECT_NAME==='现金流入小计'||cItem.PROJECT_NAME==='现金流出小计'||cItem.PROJECT_NAME==='经营活动产生的现金流量净额'||cItem.PROJECT_NAME==='筹资活动产生的现金流量净额'||cItem.PROJECT_NAME==='投资活动产生的现金流量净额'||cItem.PROJECT_NAME==='加：期初现金及现金等价物余额') ? 'bold' : '',
                              fontSize: (cItem.PROJECT_NAME==='现金流入小计'||cItem.PROJECT_NAME==='现金流出小计'||cItem.PROJECT_NAME==='经营活动产生的现金流量净额'||cItem.PROJECT_NAME==='筹资活动产生的现金流量净额'||cItem.PROJECT_NAME==='投资活动产生的现金流量净额'||cItem.PROJECT_NAME==='加：期初现金及现金等价物余额'?'15px':'12px')}">
                            <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" :label="cItem.PROJECT_NAME" :style="{fontSize: (cItem.PROJECT_NAME==='现金流入小计'||cItem.PROJECT_NAME==='现金流出小计'||cItem.PROJECT_NAME==='经营活动产生的现金流量净额'||cItem.PROJECT_NAME==='筹资活动产生的现金流量净额'||cItem.PROJECT_NAME==='投资活动产生的现金流量净额'||cItem.PROJECT_NAME==='加：期初现金及现金等价物余额'?'15px':'12px')}" style="width: 50%; ">
<!--                                <a-input-number v-model:value="cItem.LINE_NUM" :disabled="!isEdit ? true : false" placeholder="" style="width: 200px;margin-left: 50px;"></a-input-number>-->
                            </a-form-item>
                            <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" style="width: 40%;font-weight: bold;">
                                <a-input-number v-model:value="cItem.AMOUNT" :disabled="!isEdit ? true : false" placeholder="请输入金额" style="width: 400px;"></a-input-number>
                            </a-form-item>
                        </div>
                    </div>
                </div>
<!--            </a-form>-->
        </div>
        <div class="con-box" style="text-align: center;">
            <a-button type="primary" @click="onSave">保存当前</a-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'orgId',
            'loginType',
            'isAuth',
            'isEdit',
            'y'
        ])
    },
    watch: {
        y: function (newVal) {
            this.getXJLLList(newVal)
        }
    },
    data () {
        return {
            labelCol: {
                span: 9
            },
            wrapperCol: {
                span: 6
            },
            formLists: []
        }
    },
    methods: {
        // 获取现金流量表
        getXJLLList (y) {
            this.$store.dispatch('getXJLLList', { showCount: 40, currentPage: 1, ENTERPRISE_ID: this.orgId, YEAR: y, tm: new Date().getTime() }).then(res => {
                this.formLists = []
                if (res.result === 'success') {
                    this.$store.commit('SET_XXSTATUS', false)
                    let k = 0
                    for (let i in res.varList) {
                        if (res.varList[i].PROJECT_NAME === '二、投资活动产生的现金流量') {
                            k = i
                            break
                        } else {
                            if (i == 0) {
                                this.formLists.push({
                                    AMOUNT: res.varList[i].AMOUNT ? res.varList[i].AMOUNT : '',
                                    LINE_NUM: res.varList[i].LINE_NUM ? res.varList[i].LINE_NUM : '',
                                    ENTERXIANJINLIU_ID: res.varList[i].ENTERXIANJINLIU_ID,
                                    PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                    PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                    YEAR: res.varList[i].YEAR,
                                    childrenList: []
                                })
                            } else {
                                this.formLists[0].childrenList.push({
                                    AMOUNT: res.varList[i].AMOUNT ? res.varList[i].AMOUNT : '',
                                    LINE_NUM: res.varList[i].LINE_NUM ? res.varList[i].LINE_NUM : '',
                                    ENTERXIANJINLIU_ID: res.varList[i].ENTERXIANJINLIU_ID,
                                    PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                    PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                    YEAR: res.varList[i].YEAR
                                })
                            }
                        }
                    }
                    for (let i = k, len = res.varList.length; i < len; i++) {
                        if (res.varList[i].PROJECT_NAME === '三、筹资活动产生的现金流量') {
                            k = i
                            break
                        } else {
                            if (i == k) {
                                this.formLists.push({
                                    AMOUNT: res.varList[i].AMOUNT ? res.varList[i].AMOUNT : '',
                                    LINE_NUM: res.varList[i].LINE_NUM ? res.varList[i].LINE_NUM : '',
                                    ENTERXIANJINLIU_ID: res.varList[i].ENTERXIANJINLIU_ID,
                                    PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                    PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                    YEAR: res.varList[i].YEAR,
                                    childrenList: []
                                })
                            } else {
                                this.formLists[1].childrenList.push({
                                    AMOUNT: res.varList[i].AMOUNT ? res.varList[i].AMOUNT : '',
                                    LINE_NUM: res.varList[i].LINE_NUM ? res.varList[i].LINE_NUM : '',
                                    ENTERXIANJINLIU_ID: res.varList[i].ENTERXIANJINLIU_ID,
                                    PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                    PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                    YEAR: res.varList[i].YEAR
                                })
                            }
                        }
                    }
                    for (let i = k, len = res.varList.length; i < len; i++) {
                        if (res.varList[i].PROJECT_NAME === '四、汇率变动对现金的影响') {
                            k = i
                            break
                        } else {
                            if (i == k) {
                                this.formLists.push({
                                    AMOUNT: res.varList[i].AMOUNT ? res.varList[i].AMOUNT : '',
                                    LINE_NUM: res.varList[i].LINE_NUM ? res.varList[i].LINE_NUM : '',
                                    ENTERXIANJINLIU_ID: res.varList[i].ENTERXIANJINLIU_ID,
                                    PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                    PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                    YEAR: res.varList[i].YEAR,
                                    childrenList: []
                                })
                            } else {
                                this.formLists[2].childrenList.push({
                                    AMOUNT: res.varList[i].AMOUNT ? res.varList[i].AMOUNT : '',
                                    LINE_NUM: res.varList[i].LINE_NUM ? res.varList[i].LINE_NUM : '',
                                    ENTERXIANJINLIU_ID: res.varList[i].ENTERXIANJINLIU_ID,
                                    PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                    PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                    YEAR: res.varList[i].YEAR
                                })
                            }
                        }
                    }
                    for (let i = k, len = res.varList.length; i < len; i++) {
                        if (res.varList[i].PROJECT_NAME === '五、现金及现金等价物净增加额') {
                            k = i
                            break
                        } else {
                            if (i == k) {
                                this.formLists.push({
                                    AMOUNT: res.varList[i].AMOUNT ? res.varList[i].AMOUNT : '',
                                    LINE_NUM: res.varList[i].LINE_NUM ? res.varList[i].LINE_NUM : '',
                                    ENTERXIANJINLIU_ID: res.varList[i].ENTERXIANJINLIU_ID,
                                    PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                    PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                    YEAR: res.varList[i].YEAR,
                                    childrenList: []
                                })
                            } else {
                                this.formLists[3].childrenList.push({
                                    AMOUNT: res.varList[i].AMOUNT ? res.varList[i].AMOUNT : '',
                                    LINE_NUM: res.varList[i].LINE_NUM ? res.varList[i].LINE_NUM : '',
                                    ENTERXIANJINLIU_ID: res.varList[i].ENTERXIANJINLIU_ID,
                                    PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                    PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                    YEAR: res.varList[i].YEAR
                                })
                            }
                        }
                    }
                    for (let i = k, len = res.varList.length; i < len; i++) {
                        if (res.varList[i].PROJECT_NAME === '六、期末现金及现金等价物余额') {
                            k = i
                            break
                        } else {
                            if (i == k) {
                                this.formLists.push({
                                    AMOUNT: res.varList[i].AMOUNT ? res.varList[i].AMOUNT : '',
                                    LINE_NUM: res.varList[i].LINE_NUM ? res.varList[i].LINE_NUM : '',
                                    ENTERXIANJINLIU_ID: res.varList[i].ENTERXIANJINLIU_ID,
                                    PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                    PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                    YEAR: res.varList[i].YEAR,
                                    childrenList: []
                                })
                            } else {
                                this.formLists[4].childrenList.push({
                                    AMOUNT: res.varList[i].AMOUNT ? res.varList[i].AMOUNT : '',
                                    LINE_NUM: res.varList[i].LINE_NUM ? res.varList[i].LINE_NUM : '',
                                    ENTERXIANJINLIU_ID: res.varList[i].ENTERXIANJINLIU_ID,
                                    PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                    PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                    YEAR: res.varList[i].YEAR
                                })
                            }
                        }
                    }
                    for (let i = k, len = res.varList.length; i < len; i++) {
                        if (i == k) {
                            this.formLists.push({
                                AMOUNT: res.varList[i].AMOUNT ? res.varList[i].AMOUNT : '',
                                LINE_NUM: res.varList[i].LINE_NUM ? res.varList[i].LINE_NUM : '',
                                ENTERXIANJINLIU_ID: res.varList[i].ENTERXIANJINLIU_ID,
                                PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                YEAR: res.varList[i].YEAR,
                                childrenList: []
                            })
                        } else {
                            this.formLists[5].childrenList.push({
                                AMOUNT: res.varList[i].AMOUNT ? res.varList[i].AMOUNT : '',
                                LINE_NUM: res.varList[i].LINE_NUM ? res.varList[i].LINE_NUM : '',
                                ENTERXIANJINLIU_ID: res.varList[i].ENTERXIANJINLIU_ID,
                                PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                YEAR: res.varList[i].YEAR
                            })
                        }
                    }
                } else {
                    this.$message.error('系统异常，获取列表失败，请稍后重试！')
                }
            })
        },
        onSave () {
            if (!this.isEdit) {
                this.$message.info('请先点击编辑后才能进行保存！')
                return false
            }
            let DATA_IDS = '', DATA_VALUES = '', DATA_LINENUMS =''
            for (let i in this.formLists) {
                DATA_IDS += this.formLists[i].ENTERXIANJINLIU_ID + ','
                if (this.formLists[i].LINE_NUM !==''  && this.formLists[i].LINE_NUM ){
                     DATA_LINENUMS +=this.formLists[i].LINE_NUM + ','
                }else{
                    DATA_LINENUMS +='null,'
                }


                if (this.formLists[i].AMOUNT !== '' && this.formLists[i].AMOUNT) {
                    DATA_VALUES += this.formLists[i].AMOUNT + ','
                } else {
                    DATA_VALUES += '0,'
                }
                for (let k in this.formLists[i].childrenList) {
                    DATA_IDS += this.formLists[i].childrenList[k].ENTERXIANJINLIU_ID + ','
                    if (this.formLists[i].childrenList[k].AMOUNT !== '' && this.formLists[i].childrenList[k].AMOUNT) {
                        DATA_VALUES += this.formLists[i].childrenList[k].AMOUNT + ','
                    } else {
                        DATA_VALUES += '0,'
                    }

                    if (this.formLists[i].childrenList[k].LINE_NUM !==''  && this.formLists[i].childrenList[k].LINE_NUM){
                        DATA_LINENUMS +=this.formLists[i].childrenList[k].LINE_NUM + ','
                    }else{
                        DATA_LINENUMS +='null,'
                    }


                }
            }
            DATA_IDS = DATA_IDS.substring(0, DATA_IDS.length - 1)
            DATA_VALUES = DATA_VALUES.substring(0, DATA_VALUES.length - 1)
            DATA_LINENUMS = DATA_LINENUMS.substring(0,DATA_LINENUMS.length - 1)

            this.$store.dispatch('saveXJLL', {
                DATA_IDS: DATA_IDS,
                DATA_VALUES: DATA_VALUES,
                DATA_LINENUMS: DATA_LINENUMS,
                tm: new Date().getTime(0)
            }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('保存成功！')
                    this.$store.commit('SET_ISEDIT', false)
                } else {
                    this.$message.error('系统异常，保存失败，请稍后重试！')
                }
            })
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        this.getXJLLList(this.y)
    }
}
</script>
<style lang="less" scoped>
    ::v-deep(.ant-form-item){
        margin-bottom: 10px !important;
    }
.ant-form ::v-deep(label){
    //font-size: 12px;

}
::v-deep(.ant-form-item-label){
    overflow: visible;
  text-align: left;
}
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
    }

    .pd-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .content{
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            border-bottom: 1px solid #bbbbbb;
        }
    }
}
</style>
